import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Translate from "../components/translate"
import Breadcrumbs from "../components/breadcrumbs";
import Layout from "../components/layout";
import Icon from "../components/icon";
import resolveUrl from '../utils/urls/resolve-url';
import ProductsToc from "../components/products-toc";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({...props}) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
);

const BlogVindication = ({ 
  data: { mysqlBlog, allMysqlComments, allMysqlBlog }, 
  pageContext: { breadcrumbs, mysqlId },
  location: { pathname },
}) => {
  const {
    title,
    content,
    intro,
    author,
    blogContact,
    mysqlImages
  } = mysqlBlog;

  const [isOpenToc, setIsOpenToc] = useState(false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);

  return (
    <Layout pathname={pathname}>
      <div className={["card-header", 'intro-products2', b.dFlex, b.justifyContentCenter].join(" ")}>
      </div>
      <div className={[b.container, 'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={[b.container, b.mb4, b.pbLg2].join(' ')}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <h1 className={["big-title", b.my0, b.pt2].join(" ")}>{title}</h1>
              </div>
            </div>
          </div>

          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colXl8} id="content">
                {intro && <BlogContent content={intro} className={["article font-size-20", b.mb4, b.pbLg4].join(' ')} />}
                {content && <BlogContent content={content} className="article" />}

                <div className={["author-box", b.mt4, b.mtMd5, b.mb0].join(' ')}>
                  <div className={[b.row, b.alignItemsCenter].join(' ')}>
                    <div className={b.colAuto}>
                      <Thumbnail image={author.mysqlImage} className="team-person-image" alt={author.name + ' | akredo.pl'}/>
                    </div>

                    <div className={b.col}>
                      <h3 className={['title', "big-title",'blackToWhite'].join(' ')} style={{ textAlign: 'left' }}>{author.name}</h3>
                  
                      {author.title && (<>
                        <span className="blackToWhite"><Translate tKey="Guardian of department" /> </span>
                        <span className={"author-blue-span"}>{author.title}</span>
                      </>)}
                    </div>
                  </div>

                  {author.description && <BlogContent content={author.description} className='blueToGray' /> }
                  
                  <div className={[b.justifyContentCenter, b.row, b.mt4].join(" ")}>
                    {author.social_li && (
                      <div className={b.colLgAuto}>
                        <a href={author.social_li} target="_blank" rel="noreferrer" className={["blueToWhite",'author-button',b.fontWeightNormal].join(' ')}>
                          <Icon size={20} icon="LinkedIn-outline" className={[b.mr1, "icon-blog"].join(" ")}/>
                          {author.name} <Translate tKey="on LinkedIn"/>
                        </a>
                      </div>
                    )}
                  </div>
                </div>

                <Comments items={allMysqlComments.nodes} title="standard" descHeader="Komentarze" formID={mysqlId} commentType="entry"/>
                <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false); }} formID={mysqlId} commentType="entry" faq={false} />
              </div>

              <div className={[b.colXl4, b.mb5, b.pbXl5, b.orderFirst, b.orderXlLast].join(' ')}>
                {mysqlImages && (
                  <div className="sticky-box">
                    <div className={["bank-box-desktop padding-bottom visible", b.mt0].join(' ')}>
                      <div className={"bank-padding-box"}>
                        <div className={['bank-padding-img', b.dFlex, b.justifyContentCenter].join(' ')}>
                          <Thumbnail image={mysqlImages[0]} alt={title} />
                        </div>
                      </div>

                      <button className={[b.btn, "btn-light", b.mt3].join(" ")} onClick={() => { toggleForm(); }}>
                        <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>

                      <div className={[b.dFlex, b.justifyContentCenter, "blue-font",].join(" ")}>
                        <div className={b.w100}>
                          <button className={["btn-hidden", "blueToWhite", b.mt1, "margin-1", b.w100, b.alignItemsCenter, 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? (
                              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                            ) : ("")}
                            {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                          </button>
                          {isOpenToc ? (
                            <ProductsToc selector="#content .post-content h2" className='blackToWhite' />
                          ) : ("")}

                          <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                            <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                              {!isOpenToc ? ("") : (
                                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                              )}
                              {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                      
                    <BlogContact contact={blogContact} />
                  </div>
                )}
              </div>
            </div>

            {allMysqlBlog.nodes.length > 0 && <>
              <h2 className={["title", b.dBlock, b.mb4, b.pt0].join(' ')}>Inne firmy windykacyjne</h2>

              <div className={[b.row, b.mtLg5].join(" ")}>
                {allMysqlBlog.nodes.map((node, i) => (
                  <div className={[b.colXl3, b.colMd6, b.mb4, b.mbXl0].join(' ')} key={i}>
                    <div className={["bank-box", "similar-products-box", "blueToWhite", b.h100, b.mt0].join(" ")}>
                      <Link to={resolveUrl(node.terms_path, node.term_normalized, node.url)} className={b.textDecorationNone}>
                        <div className="bank-padding-img">
                          <div className="product-box-img-mobile-box">
                            <Thumbnail image={node.mysqlImages[0]} alt={node.title || ''} />
                          </div>
                        </div>
                        
                        <div className={[b.dFlex, b.alignItemsCenter, b.justifyContentCenter, b.mt0, "blackToWhite", b.px4, b.textCenter, "similar-products-text-box", b.mt1].join(" ")}>
                          {node.title}
                        </div>
                      </Link>

                      <Link to={resolveUrl(node.terms_path, node.term_normalized, node.url)} className={[b.btn,"btn-info","btn-cta", b.w100, b.mt3, b.textWhite,b.px2, b.dFlex, b.justifyContentCenter, b.alignItemsCenter].join(" ")}>
                        <Icon color="#fff" size={20} icon="Info" className={b.mr1} />
                        Przejdź do {node?.blogContact.shortname || node?.blogContact.name || node.title}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </>}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default BlogVindication;

export const query = graphql`
  query BlogVindication($mysqlId: Int!) {
    mysqlBlog(status: { eq: "publish" }, mysqlId: { eq: $mysqlId }) {
      title
      content
      intro
      author {
        name
        title
        description
        social_li
        mysqlImage {
          childImageSharp {
            gatsbyImageData(width: 64, height: 64)
          }
        }
      }
      blogContact {
        shortname
        name
        address
        phone
        www
        working_hours
        email
        affiliate
      }
      mysqlImages {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    allMysqlBlog(limit: 4, filter: {mysqlId: {nin: [$mysqlId, 1033, 1018]}, blogTerms: {elemMatch: {cluster: {eq: "vindication", nin: ["debt", ""]}}}, status: {eq: "publish"}}) {
      nodes {
        title
        terms_path
        term_normalized
        url
        blogContact {
          shortname
          name
        }
        mysqlImages {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allMysqlComments(filter: {type: {eq: "entry"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "article" }}) {
      nodes {
        ...SeoData
      }
    }
  }
`;

const BlogContact = ({ contact, className, style }) => {
  if (!contact) {
    return null;
  }

  return (
    <div className={className} style={style}>
      <div className={["toc", 'sticky', b.card, "table-of-contents",b.p3,b.mt3, 'blackToWhite'].join(' ')}>
        {(contact.shortname || contact.name) && <h4 className="toc-title">Dane kontaktowe {contact.shortname || contact.name}</h4>}
        
        {contact.name && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Nazwa firmy</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px' }}>{contact.name}</div>
        </div>}

        {contact.address && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Adres</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px' }}>{contact.address}</div>
        </div>}
        
        {contact.phone && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Telefon</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px' }}>{contact.phone}</div>
        </div>}
        
        {contact.www && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Adres strony</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px', overflowWrap: 'break-word' }}>{contact.www}</div>
        </div>}
        
        {contact.working_hours && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Godziny pracy</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px' }}>{contact.working_hours}</div>
        </div>}
        
        {contact.email && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Adres e-mail</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')} style={{ fontSize: '18px', overflowWrap: 'break-word' }}>{contact.email}</div>
        </div>}
        
        {contact.affiliate && <div className={[b.row, b.mt2, b.pt1].join(' ')}>
          <div className={[b.col4, b.colSm3, b.colLg2, b.colXl4, b.pt1].join(' ')}>Wniosek</div>
          <div className={[b.col8, b.colSm9, b.colLg10, b.colXl8].join(' ')}>
            <a href={contact.affiliate} className={[b.btn, "btn-success", b.m0].join(" ")} target="_blank" rel="noreferrer noopener nofollow" style={{ color: '#fff', fontWeight: 500, minWidth: 'auto' }}>
              <Translate tKey="Apply for loan" />
            </a>
          </div>
        </div>}
      </div>
    </div>
  );
};